import React from "react";

export default function Intro(){
    return(
        <div className="pt-10">
            <h1>About Us</h1>
            <div>
                Founded by Katherine, who has worked with clients like Amazon, Boeing, and Honda.
                Katherine specializes in website development, manufacturing software consulting, and smart contracts/blockchain coding. At age 15, she developed a website that had over thousands of views, and has continued her programming journey ever since.
            </div>
        </div>
    )
}