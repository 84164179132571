import React from "react";
import "./divider.css";

function Divider() {
  return (
    <div className="m-auto max-w-full relative text-themeBlue" style={{marginTop: '80px',  height: '20px'}}>
      <div className="divider overflow-hidden"></div>
    </div>
  );
}

export default Divider;
