import React from "react";
import { Parallax } from "react-scroll-parallax";
import MacBook from "./macbook";

export default function MainImg(){
    return(
        <div className="w-full">
            <div className="text-center text-4xl pt-6 pb-4 ">
                Tell us what website you want and we'll do the hard part for you
            </div>
            <MacBook/>
            <Parallax x={['300px', '-100px']}>
            <div className="text-center pt-6 text-xl">
                Grow your business online, whether it's for your small business, your NFT website, or your personal website - we've got you covered
            </div>
            </Parallax>
        </div>
    )
}