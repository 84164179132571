import * as React from "react"
// import { useEffect, useState } from "react"


import Layout from "../components/layout"
import Seo from "../components/seo"

import { ParallaxProvider } from 'react-scroll-parallax';
import MainImg from "../components/main-img"
import Intro from "../components/intro"
import Divider from "../components/divider"


const TestPage = () => {
  // const [name, setName] = useState('');

  // useEffect(() => {
  //   console.log('rendered ', name);

  //   return () => {
  //     // cleanup, unmount
  //     console.log('we unmounted');

  //   }
  // }, [name])
  
  return(
    <ParallaxProvider>
  <Layout>
    <Seo title="Home" />
    <MainImg/>

    {/* <input type="text" value={name} onChange={(e) => setName(e.target.value)} /> */}
    {/* <StaticImage
      src="../images/gatsby-astronaut.png"
      width={300}
      quality={95}
      formats={["auto", "webp", "avif"]}
      alt="A Gatsby astronaut"
      style={{ marginBottom: `1.45rem` }}
    /> */}
    {/* <p>
      <Link to="/page-2/">Go to page 2</Link> <br />
      <Link to="/using-typescript/">Go to "Using TypeScript"</Link> <br />
      <Link to="/using-ssr">Go to "Using SSR"</Link> <br />
      <Link to="/using-dsg">Go to "Using DSG"</Link>
    </p> */}
    <Divider />
    <Intro />
    <Divider />
    <div className="h-screen"></div>
  </Layout>
  </ParallaxProvider>
)
  }

export default TestPage
